#partenaires {
  padding: 45px 0;

  .pre-title {
    margin-bottom: 45px;
  }

  .container,
  .mainRow {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 2rem;

    @include media-breakpoint-up(lg) {
      --bs-gutter-x: 2rem;
      --bs-gutter-y: 6rem;
    }
  }

  img {
    width: 100%;
  }
}

// -----------------------------------------------------------------------------
// Basic typography style for text.
// -----------------------------------------------------------------------------

html {
  font-size: var(--font-level-16);
}

body {
  font-family: $font-now-alt;
  font-size: 100%;
  font-weight: $fw-light;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-title;
}

// Basic styles for links
a {
  color: makeCSSVariables('primary');
  text-decoration: none;

  @include on-event {
    color: makeCSSVariables('primary-darken');
    text-decoration: underline;
  }
}

p {
  color: $gray-500;
  font-size: 18px;
  font-weight: $fw-light;
  line-height: 1.22;

  @include media-breakpoint-only(md) {
    font-size: 20px;
  }
}

.pre-title {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 2px;
  color: $black;
  font-family: $font-pretitle;
  font-size: 30px;
  letter-spacing: 1.2px;
  line-height: 1.2;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 96px;
    height: 1px;
    background-color: $black;
  }
}

.title {
  color: $black;
  font-family: $font-title;
  font-size: 30px;
  font-weight: $fw-bold;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    font-size: 35px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 35px;
  }

  &__secondary {
    color: $secondary;
  }
}

ul.list {
  margin-top: 30px;
  padding-left: 32px;
  list-style: none;

  li {
    position: relative;
    padding-bottom: 8px;
    color: $gray-700;
    font-size: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: -32px;
      width: 11px;
      height: 11px;
      border-radius: 100px;
      background-color: $primary;
    }
  }
}

.text-nowrap {
  white-space: nowrap;
}

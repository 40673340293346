// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.topMessage {
  display: none;

  &.active-leaves {
    display:block;
    height:auto;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    background-color: $orange;
  }

  .leave-message {
    padding: 10px 0;

    p {
      margin-bottom: 0;
      color: $white;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.22;
      text-align: center;
      text-wrap: balance;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
  }
}

.mainHeader {
  position: fixed;
  z-index: 500;
  top: 0;
  right: 0;
  left: 0;
  flex-shrink: 1;
  height: var(--header-height);
  padding: var(--header-padding);
  transition: all 300ms ease-in-out;
  transition-property: padding, height;
  background-color: $white;
  box-shadow: 0 3px 6px #00000029;
  font-family: $font-now;
  text-align: center;

  @include media-breakpoint-down(lg) {
    position: fixed;
  }

  @include media-breakpoint-up(lg) {
    //background-color: rgba(2, 0, 5, 0.8);
    //backdrop-filter: blur(4px);

    body.fixed-header & {
      height: 80px;
      padding: 15px var(--header-padding);
    }
  }

  .container {
    @include media-breakpoint-down(lg) {
      max-width: initial;
    }
  }

  .container,
  .row {
    align-items: center;
    height: 100%;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  .mainRow {
    @include media-breakpoint-down(lg) {
      justify-content: space-between;
    }

    @include media-breakpoint-down(xsm) {
      flex-wrap: nowrap;
    }
  }

  .logo {
    height: 100%;
    padding: var(--header-logo-padding);

    @include media-breakpoint-up(lg) {
      margin-right: 25px;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 30px;
    }

    @include media-breakpoint-up(xxl) {
      margin-right: 100px;
    }

    img {
      width: auto;
      height: 100%;
    }
  }

  .baseline {
    font-size: 10px;
    font-weight: 600;
    line-height: 1.1;
    text-align: left;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 11px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 12px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 17px;
    }
  }

  .nav {
    display: flex;
    justify-content: flex-start;

    @include media-breakpoint-down(lg) {
      position: fixed;
      top: var(--header-height);
      right: 0;
      bottom: 0;
      left: 0;
      flex: 1;
      flex-direction: column;
      transform: translateX(105%);
      //transition: transform 500ms ease-in-out;
      transition: transform 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
      background: $white;
    }

    .nav-and-cta {
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        padding: 20px 20px 40px;
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }

    body.menu-opened & {
      transform: translateX(0);
    }

    ul.header-menu {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      li {
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;

          &:not(:last-child) {
            margin-right: 35px;
          }
        }

        @include media-breakpoint-up(xl) {
          &:not(:last-child) {
            margin-right: 55px;
          }
        }
      }

      a {
        transition: color 200ms ease-in-out;
        color: $gray-700;
        font-size: 15px;
        font-weight: $fw-light;

        @include media-breakpoint-only(lg) {
          font-size: 14px;
        }

        @include on-event {
          color: $black;
          text-decoration: none;
        }
      }
    }

    li.active a {
      color: $black;
      font-weight: 700;
    }
  }

  .button {
    display: block;
    padding: 12px 20px;
    font-size: 15px;

    @include media-breakpoint-only(lg) {
      padding: 6px 12px;
      font-size: 14px;
    }
  }

  .burger-menu {
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .burger-menu__button {
      display: flex;
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      svg {
        height: 44px;
      }
    }

    .line {
      transition:
        stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
      stroke-width: 6;
      stroke: $black;
      fill: none;
    }

    .line1 {
      stroke-width: 6;
      stroke-dasharray: 60 207;
    }

    .line2 {
      stroke-width: 6;
      stroke-dasharray: 60 60;
    }

    .line3 {
      stroke-width: 6;
      stroke-dasharray: 60 207;
    }

    .line1 {
      body.menu-opened & {
        stroke-width: 6;
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
      }
    }

    .line2 {
      body.menu-opened & {
        stroke-width: 6;
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
      }
    }

    .line3 {
      body.menu-opened & {
        stroke-width: 6;
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
      }
    }
  }
}

$icomoon-font-family: "icomoon-srp" !default;
$icomoon-font-path: "fonts" !default;

$iconSrp-logo-glanum: unquote('"\\e90a"');
$iconSrp-headphones: unquote('"\\e909"');
$iconSrp-care: unquote('"\\e900"');
$iconSrp-shipping: unquote('"\\e901"');
$iconSrp-quality-assurance: unquote('"\\e902"');
$iconSrp-lightbulb: unquote('"\\e903"');
$iconSrp-design-tools: unquote('"\\e904"');
$iconSrp-flag-france-path1: unquote('"\\e905"');
$iconSrp-flag-france-path2: unquote('"\\e906"');
$iconSrp-flag-france-path3: unquote('"\\e907"');
$iconSrp-color-sample: unquote('"\\e908"');


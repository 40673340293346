// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

//
// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
//
*,
*::before,
*::after {
  box-sizing: inherit;
}

//Set up a decent box model on the root element
html {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--header-height);
  overflow-x: hidden;
  background-color: $white;
  color: $black;



  &.menu-opened,
  &.modal-open,
  &.modal-active {
    overflow: hidden;
  }

  &.modal-open {
    padding-right: var(--scrollbarWidth);
  }

  &:has(.active-leaves) {
    --top-bar-height: 40px;
  }
}

.page {
  flex: 1;
  overflow: hidden;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

//.container {
//  @include media-breakpoint-down(sm) {
//    max-width: initial;
//  }
//}

.section--centered {
  text-align: center;

  .pre-title {
    &::after {
      left: 50%;
      width: 60px;
      transform: translateX(-50%);
    }
  }
}

.fancybox__content {
  padding: 0;
}

.fancybox__slide {
  padding: 45px 10px 10px;

  @include media-breakpoint-up(lg) {
    padding: 48px 30px 10px;
  }
}

.fancybox-custom {
  width: 100%;
  max-width: 1100px !important;
  padding: 20px;

  @include media-breakpoint-up(md) {
    padding: 30px;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px;
  }

  .h1-like {
    margin-bottom: 20px;
  }

  .h2-like {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
  }

  .h3-like {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 19px;
    font-weight: 600;
  }

  img {
    margin-bottom: 30px;
    border-radius: 10px;
  }

  b,
  strong {
    font-weight: 600;
  }

  .pre-title {
    text-align: center;

    &::after {
      left: 50%;
      width: 60px;
      transform: translateX(-50%);
    }
  }
}

table.table-legal {
  width: 100%;
  margin-bottom: 30px;
  border-collapse: collapse;
  border: 2px solid $gray-700;
  font-size: 14px;

  th {
    background-color: $gray-700;

    &,
    p {
      color: #fff;
    }
  }

  th,
  td {
    padding: 14px;
    border: 2px solid $gray-700;
  }

  p {
    margin: 0;
    font-size: 14px;
  }
}

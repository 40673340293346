// Img
$pictures-path: '../img';

//fonts
$fonts-path: '../fonts';
$icomoon-font-path: '#{$fonts-path}/icomoon/fonts';

$font-ar-bonnie: 'AR BONNIE';
$font-now: 'Now';
$font-now-alt: 'Now Alt';

$font-pretitle: $font-ar-bonnie;
$font-title: $font-now-alt;
$font-text: $font-now;

//$fonts: (
//  'text': (
//    'name': $font-text,
//    'serif': sans-serif
//  ),
//  'title': (
//    'name': $font-title
//  )
//);

$theme-fonts: (
  'font-title': $font-title,
  'font-text': $font-text
);

// Font-sizes must be converted to rem, use function calculate-rem or min-max-interpolation
$font-sizes: (
  16: calculate-rem(16)
);

// // Typography sizes
// $title-sizes-mobile: (
//   1: 36,
//   2: 28,
//   3: 25,
//   4: 22,
//   5: 20,
//   6: 17,
//   7: 16
// );

// $title-sizes-xl: (
//   1: 70,
//   2: 48,
//   3: 38,
//   4: 30,
//   5: 24,
//   6: 18,
//   7: 17
// );

// Font Weight
$fw-light: 300;
$fw-medium: 500;
$fw-bold: bold;

$state-colors: (
  'danger': #dc3545,
  'warning': #ffc107,
  'info': #17a2b8,
  'success': #28a745
);

//colors

$orange: #ee921e;
$red: #cb3e20;
$peach: #f7d3bc;

$primary: $orange;
$primary-darken: darken($primary, 4%);

$secondary: $red;
$secondary-darken: darken($secondary, 4%);

$gray-50: #fbfbfb; // custom ok
$gray-100: #f6f6f6; // custom ok
$gray-200: #e5e5e5;
$gray-300: #d4d4d4;
$gray-400: #a3a3a3;
$gray-500: #727673; // custom ok
$gray-600: #525252;
$gray-700: #404040;
$gray-800: #262626;
$gray-900: #171717;

$white: #fff;
$black: #262c28;

// Colors must be named in kebab case based on this : https://colornamer.robertcooper.me/
$colors: (
  'primary': $primary,
  'primary-darken': $primary-darken,
  'secondary': $secondary,
  'secondary-darken': $secondary-darken,
  // "third": $third,
  // "third-darken": $third-darken,
  // "fourth": $fourth,
  // "fourth-darken": $fourth-darken,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'white': $white,
  'black': $black,
  'text-color': $gray-400,
  'input-color': $gray-400,
  'bg-color': $gray-100
);

$custom-variable-prefix: srp-;
$enable-smooth-scroll: false;

$spacings: (
  base: calculate-rem(30)
);

// breakpoints
$grid-breakpoints: (
  xs: 0,
  xsm: 358px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  fhd: 1700px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  fhd: 1440px
);

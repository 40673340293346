// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

//
// Clear inner floats
//
.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

//
// Hide text while making it readable for screen readers
// 1. Needed in WebKit-based browsers because of an implementation bug;
//    See: https://code.google.com/p/chromium/issues/detail?id=457146
//
.hide-text {
  padding: 0; /* 1 */
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

//
// Hide element while making it readable for screen readers
// Shamelessly borrowed from HTML5Boilerplate:
// https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
//
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

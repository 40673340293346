#prefooter {
  padding: 40px 0;
  margin-top: 50px;
  background-color: $gray-100;
  text-align: center;

  .item {
    &__image {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
      border-radius: 5px;
    }
    &__icon {
      display: block;
      font-size: 46px;
      margin-bottom: 20px;
    }
    &__title {
      font-family: $font-now;
      font-size: 20px;
      font-weight: $fw-light;
      color: $black;
      line-height: 1.2;
      margin-bottom: 6px;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*!
 * Bootstrap Reboot v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-primary: #ee921e;
  --bs-primary-darken: #e68811;
  --bs-secondary: #cb3e20;
  --bs-secondary-darken: #b9391d;
  --bs-gray-100: #f6f6f6;
  --bs-gray-200: #e5e5e5;
  --bs-gray-300: #d4d4d4;
  --bs-gray-400: #a3a3a3;
  --bs-gray-500: #727673;
  --bs-gray-600: #525252;
  --bs-gray-700: #404040;
  --bs-gray-800: #262626;
  --bs-gray-900: #171717;
  --bs-white: #fff;
  --bs-black: #262c28;
  --bs-text-color: #a3a3a3;
  --bs-input-color: #a3a3a3;
  --bs-bg-color: #f6f6f6;
  --bs-gray-100: #f6f6f6;
  --bs-gray-200: #e5e5e5;
  --bs-gray-300: #d4d4d4;
  --bs-gray-400: #a3a3a3;
  --bs-gray-500: #727673;
  --bs-gray-600: #525252;
  --bs-gray-700: #404040;
  --bs-gray-800: #262626;
  --bs-gray-900: #171717;
  --bs-primary: #ee921e;
  --bs-secondary: #cb3e20;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #cb3e20;
  --bs-light: #f6f6f6;
  --bs-dark: #171717;
  --bs-primary-rgb: 238, 146, 30;
  --bs-secondary-rgb: 203, 62, 32;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 203, 62, 32;
  --bs-light-rgb: 246, 246, 246;
  --bs-dark-rgb: 23, 23, 23;
  --bs-primary-text-emphasis: #5f3a0c;
  --bs-secondary-text-emphasis: #51190d;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #51190d;
  --bs-light-text-emphasis: #404040;
  --bs-dark-text-emphasis: #404040;
  --bs-primary-bg-subtle: #fce9d2;
  --bs-secondary-bg-subtle: #f5d8d2;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f5d8d2;
  --bs-light-bg-subtle: #fbfbfb;
  --bs-dark-bg-subtle: #a3a3a3;
  --bs-primary-border-subtle: #f8d3a5;
  --bs-secondary-border-subtle: #eab2a6;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #eab2a6;
  --bs-light-border-subtle: #e5e5e5;
  --bs-dark-border-subtle: #727673;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 38, 44, 40;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #171717;
  --bs-body-color-rgb: 23, 23, 23;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #262c28;
  --bs-emphasis-color-rgb: 38, 44, 40;
  --bs-secondary-color: rgba(23, 23, 23, 0.75);
  --bs-secondary-color-rgb: 23, 23, 23;
  --bs-secondary-bg: #e5e5e5;
  --bs-secondary-bg-rgb: 229, 229, 229;
  --bs-tertiary-color: rgba(23, 23, 23, 0.5);
  --bs-tertiary-color-rgb: 23, 23, 23;
  --bs-tertiary-bg: #f6f6f6;
  --bs-tertiary-bg-rgb: 246, 246, 246;
  --bs-heading-color: inherit;
  --bs-link-color: #ee921e;
  --bs-link-color-rgb: 238, 146, 30;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #be7518;
  --bs-link-hover-color-rgb: 190, 117, 24;
  --bs-code-color: #d63384;
  --bs-highlight-color: #171717;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #d4d4d4;
  --bs-border-color-translucent: rgba(38, 44, 40, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(38, 44, 40, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(38, 44, 40, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(38, 44, 40, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(38, 44, 40, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(238, 146, 30, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #cb3e20;
  --bs-form-invalid-border-color: #cb3e20;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #d4d4d4;
  --bs-body-color-rgb: 212, 212, 212;
  --bs-body-bg: #171717;
  --bs-body-bg-rgb: 23, 23, 23;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(212, 212, 212, 0.75);
  --bs-secondary-color-rgb: 212, 212, 212;
  --bs-secondary-bg: #262626;
  --bs-secondary-bg-rgb: 38, 38, 38;
  --bs-tertiary-color: rgba(212, 212, 212, 0.5);
  --bs-tertiary-color-rgb: 212, 212, 212;
  --bs-tertiary-bg: #1f1f1f;
  --bs-tertiary-bg-rgb: 31, 31, 31;
  --bs-primary-text-emphasis: #f5be78;
  --bs-secondary-text-emphasis: #e08b79;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #e08b79;
  --bs-light-text-emphasis: #f6f6f6;
  --bs-dark-text-emphasis: #d4d4d4;
  --bs-primary-bg-subtle: #301d06;
  --bs-secondary-bg-subtle: #290c06;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #290c06;
  --bs-light-bg-subtle: #262626;
  --bs-dark-bg-subtle: #262927;
  --bs-primary-border-subtle: #8f5812;
  --bs-secondary-border-subtle: #7a2513;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #7a2513;
  --bs-light-border-subtle: #404040;
  --bs-dark-border-subtle: #262626;
  --bs-heading-color: inherit;
  --bs-link-color: #f5be78;
  --bs-link-hover-color: #f7cb93;
  --bs-link-color-rgb: 245, 190, 120;
  --bs-link-hover-color-rgb: 247, 203, 147;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #d4d4d4;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #404040;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #e08b79;
  --bs-form-invalid-border-color: #e08b79;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(38, 44, 40, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

/*!
 * Bootstrap Grid v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.container,
.container-fluid,
.container-fhd,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-xsm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xsm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1440px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1320px;
  }
}
@media (min-width: 1700px) {
  .container-fhd, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1440px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-xsm: 358px;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1440px;
  --bs-breakpoint-fhd: 1700px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 358px) {
  .col-xsm {
    flex: 1 0 0%;
  }
  .row-cols-xsm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xsm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xsm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xsm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xsm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xsm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xsm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xsm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xsm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xsm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xsm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xsm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xsm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xsm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xsm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xsm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xsm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xsm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xsm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xsm-0 {
    margin-left: 0;
  }
  .offset-xsm-1 {
    margin-left: 8.33333333%;
  }
  .offset-xsm-2 {
    margin-left: 16.66666667%;
  }
  .offset-xsm-3 {
    margin-left: 25%;
  }
  .offset-xsm-4 {
    margin-left: 33.33333333%;
  }
  .offset-xsm-5 {
    margin-left: 41.66666667%;
  }
  .offset-xsm-6 {
    margin-left: 50%;
  }
  .offset-xsm-7 {
    margin-left: 58.33333333%;
  }
  .offset-xsm-8 {
    margin-left: 66.66666667%;
  }
  .offset-xsm-9 {
    margin-left: 75%;
  }
  .offset-xsm-10 {
    margin-left: 83.33333333%;
  }
  .offset-xsm-11 {
    margin-left: 91.66666667%;
  }
  .g-xsm-0,
  .gx-xsm-0 {
    --bs-gutter-x: 0;
  }
  .g-xsm-0,
  .gy-xsm-0 {
    --bs-gutter-y: 0;
  }
  .g-xsm-1,
  .gx-xsm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xsm-1,
  .gy-xsm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xsm-2,
  .gx-xsm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xsm-2,
  .gy-xsm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xsm-3,
  .gx-xsm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xsm-3,
  .gy-xsm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xsm-4,
  .gx-xsm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xsm-4,
  .gy-xsm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xsm-5,
  .gx-xsm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xsm-5,
  .gy-xsm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1440px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1700px) {
  .col-fhd {
    flex: 1 0 0%;
  }
  .row-cols-fhd-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-fhd-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-fhd-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-fhd-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-fhd-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-fhd-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-fhd-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-fhd-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-fhd-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-fhd-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-fhd-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-fhd-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-fhd-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-fhd-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-fhd-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-fhd-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-fhd-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-fhd-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-fhd-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-fhd-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-fhd-0 {
    margin-left: 0;
  }
  .offset-fhd-1 {
    margin-left: 8.33333333%;
  }
  .offset-fhd-2 {
    margin-left: 16.66666667%;
  }
  .offset-fhd-3 {
    margin-left: 25%;
  }
  .offset-fhd-4 {
    margin-left: 33.33333333%;
  }
  .offset-fhd-5 {
    margin-left: 41.66666667%;
  }
  .offset-fhd-6 {
    margin-left: 50%;
  }
  .offset-fhd-7 {
    margin-left: 58.33333333%;
  }
  .offset-fhd-8 {
    margin-left: 66.66666667%;
  }
  .offset-fhd-9 {
    margin-left: 75%;
  }
  .offset-fhd-10 {
    margin-left: 83.33333333%;
  }
  .offset-fhd-11 {
    margin-left: 91.66666667%;
  }
  .g-fhd-0,
  .gx-fhd-0 {
    --bs-gutter-x: 0;
  }
  .g-fhd-0,
  .gy-fhd-0 {
    --bs-gutter-y: 0;
  }
  .g-fhd-1,
  .gx-fhd-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-fhd-1,
  .gy-fhd-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-fhd-2,
  .gx-fhd-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-fhd-2,
  .gy-fhd-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-fhd-3,
  .gx-fhd-3 {
    --bs-gutter-x: 1rem;
  }
  .g-fhd-3,
  .gy-fhd-3 {
    --bs-gutter-y: 1rem;
  }
  .g-fhd-4,
  .gx-fhd-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-fhd-4,
  .gy-fhd-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-fhd-5,
  .gx-fhd-5 {
    --bs-gutter-x: 3rem;
  }
  .g-fhd-5,
  .gy-fhd-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 358px) {
  .d-xsm-inline {
    display: inline !important;
  }
  .d-xsm-inline-block {
    display: inline-block !important;
  }
  .d-xsm-block {
    display: block !important;
  }
  .d-xsm-grid {
    display: grid !important;
  }
  .d-xsm-inline-grid {
    display: inline-grid !important;
  }
  .d-xsm-table {
    display: table !important;
  }
  .d-xsm-table-row {
    display: table-row !important;
  }
  .d-xsm-table-cell {
    display: table-cell !important;
  }
  .d-xsm-flex {
    display: flex !important;
  }
  .d-xsm-inline-flex {
    display: inline-flex !important;
  }
  .d-xsm-none {
    display: none !important;
  }
  .flex-xsm-fill {
    flex: 1 1 auto !important;
  }
  .flex-xsm-row {
    flex-direction: row !important;
  }
  .flex-xsm-column {
    flex-direction: column !important;
  }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xsm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xsm-start {
    justify-content: flex-start !important;
  }
  .justify-content-xsm-end {
    justify-content: flex-end !important;
  }
  .justify-content-xsm-center {
    justify-content: center !important;
  }
  .justify-content-xsm-between {
    justify-content: space-between !important;
  }
  .justify-content-xsm-around {
    justify-content: space-around !important;
  }
  .justify-content-xsm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xsm-start {
    align-items: flex-start !important;
  }
  .align-items-xsm-end {
    align-items: flex-end !important;
  }
  .align-items-xsm-center {
    align-items: center !important;
  }
  .align-items-xsm-baseline {
    align-items: baseline !important;
  }
  .align-items-xsm-stretch {
    align-items: stretch !important;
  }
  .align-content-xsm-start {
    align-content: flex-start !important;
  }
  .align-content-xsm-end {
    align-content: flex-end !important;
  }
  .align-content-xsm-center {
    align-content: center !important;
  }
  .align-content-xsm-between {
    align-content: space-between !important;
  }
  .align-content-xsm-around {
    align-content: space-around !important;
  }
  .align-content-xsm-stretch {
    align-content: stretch !important;
  }
  .align-self-xsm-auto {
    align-self: auto !important;
  }
  .align-self-xsm-start {
    align-self: flex-start !important;
  }
  .align-self-xsm-end {
    align-self: flex-end !important;
  }
  .align-self-xsm-center {
    align-self: center !important;
  }
  .align-self-xsm-baseline {
    align-self: baseline !important;
  }
  .align-self-xsm-stretch {
    align-self: stretch !important;
  }
  .order-xsm-first {
    order: -1 !important;
  }
  .order-xsm-0 {
    order: 0 !important;
  }
  .order-xsm-1 {
    order: 1 !important;
  }
  .order-xsm-2 {
    order: 2 !important;
  }
  .order-xsm-3 {
    order: 3 !important;
  }
  .order-xsm-4 {
    order: 4 !important;
  }
  .order-xsm-5 {
    order: 5 !important;
  }
  .order-xsm-last {
    order: 6 !important;
  }
  .m-xsm-0 {
    margin: 0 !important;
  }
  .m-xsm-1 {
    margin: 0.25rem !important;
  }
  .m-xsm-2 {
    margin: 0.5rem !important;
  }
  .m-xsm-3 {
    margin: 1rem !important;
  }
  .m-xsm-4 {
    margin: 1.5rem !important;
  }
  .m-xsm-5 {
    margin: 3rem !important;
  }
  .m-xsm-auto {
    margin: auto !important;
  }
  .mx-xsm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xsm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xsm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xsm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xsm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xsm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xsm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xsm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xsm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xsm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xsm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xsm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xsm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xsm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xsm-0 {
    margin-top: 0 !important;
  }
  .mt-xsm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xsm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xsm-3 {
    margin-top: 1rem !important;
  }
  .mt-xsm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xsm-5 {
    margin-top: 3rem !important;
  }
  .mt-xsm-auto {
    margin-top: auto !important;
  }
  .me-xsm-0 {
    margin-right: 0 !important;
  }
  .me-xsm-1 {
    margin-right: 0.25rem !important;
  }
  .me-xsm-2 {
    margin-right: 0.5rem !important;
  }
  .me-xsm-3 {
    margin-right: 1rem !important;
  }
  .me-xsm-4 {
    margin-right: 1.5rem !important;
  }
  .me-xsm-5 {
    margin-right: 3rem !important;
  }
  .me-xsm-auto {
    margin-right: auto !important;
  }
  .mb-xsm-0 {
    margin-bottom: 0 !important;
  }
  .mb-xsm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xsm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xsm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xsm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xsm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xsm-auto {
    margin-bottom: auto !important;
  }
  .ms-xsm-0 {
    margin-left: 0 !important;
  }
  .ms-xsm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xsm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xsm-3 {
    margin-left: 1rem !important;
  }
  .ms-xsm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xsm-5 {
    margin-left: 3rem !important;
  }
  .ms-xsm-auto {
    margin-left: auto !important;
  }
  .p-xsm-0 {
    padding: 0 !important;
  }
  .p-xsm-1 {
    padding: 0.25rem !important;
  }
  .p-xsm-2 {
    padding: 0.5rem !important;
  }
  .p-xsm-3 {
    padding: 1rem !important;
  }
  .p-xsm-4 {
    padding: 1.5rem !important;
  }
  .p-xsm-5 {
    padding: 3rem !important;
  }
  .px-xsm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xsm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xsm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xsm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xsm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xsm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xsm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xsm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xsm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xsm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xsm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xsm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xsm-0 {
    padding-top: 0 !important;
  }
  .pt-xsm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xsm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xsm-3 {
    padding-top: 1rem !important;
  }
  .pt-xsm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xsm-5 {
    padding-top: 3rem !important;
  }
  .pe-xsm-0 {
    padding-right: 0 !important;
  }
  .pe-xsm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xsm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xsm-3 {
    padding-right: 1rem !important;
  }
  .pe-xsm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xsm-5 {
    padding-right: 3rem !important;
  }
  .pb-xsm-0 {
    padding-bottom: 0 !important;
  }
  .pb-xsm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xsm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xsm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xsm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xsm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xsm-0 {
    padding-left: 0 !important;
  }
  .ps-xsm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xsm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xsm-3 {
    padding-left: 1rem !important;
  }
  .ps-xsm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xsm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1440px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1700px) {
  .d-fhd-inline {
    display: inline !important;
  }
  .d-fhd-inline-block {
    display: inline-block !important;
  }
  .d-fhd-block {
    display: block !important;
  }
  .d-fhd-grid {
    display: grid !important;
  }
  .d-fhd-inline-grid {
    display: inline-grid !important;
  }
  .d-fhd-table {
    display: table !important;
  }
  .d-fhd-table-row {
    display: table-row !important;
  }
  .d-fhd-table-cell {
    display: table-cell !important;
  }
  .d-fhd-flex {
    display: flex !important;
  }
  .d-fhd-inline-flex {
    display: inline-flex !important;
  }
  .d-fhd-none {
    display: none !important;
  }
  .flex-fhd-fill {
    flex: 1 1 auto !important;
  }
  .flex-fhd-row {
    flex-direction: row !important;
  }
  .flex-fhd-column {
    flex-direction: column !important;
  }
  .flex-fhd-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-fhd-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-fhd-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-fhd-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-fhd-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-fhd-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-fhd-wrap {
    flex-wrap: wrap !important;
  }
  .flex-fhd-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-fhd-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-fhd-start {
    justify-content: flex-start !important;
  }
  .justify-content-fhd-end {
    justify-content: flex-end !important;
  }
  .justify-content-fhd-center {
    justify-content: center !important;
  }
  .justify-content-fhd-between {
    justify-content: space-between !important;
  }
  .justify-content-fhd-around {
    justify-content: space-around !important;
  }
  .justify-content-fhd-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-fhd-start {
    align-items: flex-start !important;
  }
  .align-items-fhd-end {
    align-items: flex-end !important;
  }
  .align-items-fhd-center {
    align-items: center !important;
  }
  .align-items-fhd-baseline {
    align-items: baseline !important;
  }
  .align-items-fhd-stretch {
    align-items: stretch !important;
  }
  .align-content-fhd-start {
    align-content: flex-start !important;
  }
  .align-content-fhd-end {
    align-content: flex-end !important;
  }
  .align-content-fhd-center {
    align-content: center !important;
  }
  .align-content-fhd-between {
    align-content: space-between !important;
  }
  .align-content-fhd-around {
    align-content: space-around !important;
  }
  .align-content-fhd-stretch {
    align-content: stretch !important;
  }
  .align-self-fhd-auto {
    align-self: auto !important;
  }
  .align-self-fhd-start {
    align-self: flex-start !important;
  }
  .align-self-fhd-end {
    align-self: flex-end !important;
  }
  .align-self-fhd-center {
    align-self: center !important;
  }
  .align-self-fhd-baseline {
    align-self: baseline !important;
  }
  .align-self-fhd-stretch {
    align-self: stretch !important;
  }
  .order-fhd-first {
    order: -1 !important;
  }
  .order-fhd-0 {
    order: 0 !important;
  }
  .order-fhd-1 {
    order: 1 !important;
  }
  .order-fhd-2 {
    order: 2 !important;
  }
  .order-fhd-3 {
    order: 3 !important;
  }
  .order-fhd-4 {
    order: 4 !important;
  }
  .order-fhd-5 {
    order: 5 !important;
  }
  .order-fhd-last {
    order: 6 !important;
  }
  .m-fhd-0 {
    margin: 0 !important;
  }
  .m-fhd-1 {
    margin: 0.25rem !important;
  }
  .m-fhd-2 {
    margin: 0.5rem !important;
  }
  .m-fhd-3 {
    margin: 1rem !important;
  }
  .m-fhd-4 {
    margin: 1.5rem !important;
  }
  .m-fhd-5 {
    margin: 3rem !important;
  }
  .m-fhd-auto {
    margin: auto !important;
  }
  .mx-fhd-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-fhd-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-fhd-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-fhd-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-fhd-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-fhd-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-fhd-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-fhd-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-fhd-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-fhd-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-fhd-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-fhd-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-fhd-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-fhd-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-fhd-0 {
    margin-top: 0 !important;
  }
  .mt-fhd-1 {
    margin-top: 0.25rem !important;
  }
  .mt-fhd-2 {
    margin-top: 0.5rem !important;
  }
  .mt-fhd-3 {
    margin-top: 1rem !important;
  }
  .mt-fhd-4 {
    margin-top: 1.5rem !important;
  }
  .mt-fhd-5 {
    margin-top: 3rem !important;
  }
  .mt-fhd-auto {
    margin-top: auto !important;
  }
  .me-fhd-0 {
    margin-right: 0 !important;
  }
  .me-fhd-1 {
    margin-right: 0.25rem !important;
  }
  .me-fhd-2 {
    margin-right: 0.5rem !important;
  }
  .me-fhd-3 {
    margin-right: 1rem !important;
  }
  .me-fhd-4 {
    margin-right: 1.5rem !important;
  }
  .me-fhd-5 {
    margin-right: 3rem !important;
  }
  .me-fhd-auto {
    margin-right: auto !important;
  }
  .mb-fhd-0 {
    margin-bottom: 0 !important;
  }
  .mb-fhd-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-fhd-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-fhd-3 {
    margin-bottom: 1rem !important;
  }
  .mb-fhd-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-fhd-5 {
    margin-bottom: 3rem !important;
  }
  .mb-fhd-auto {
    margin-bottom: auto !important;
  }
  .ms-fhd-0 {
    margin-left: 0 !important;
  }
  .ms-fhd-1 {
    margin-left: 0.25rem !important;
  }
  .ms-fhd-2 {
    margin-left: 0.5rem !important;
  }
  .ms-fhd-3 {
    margin-left: 1rem !important;
  }
  .ms-fhd-4 {
    margin-left: 1.5rem !important;
  }
  .ms-fhd-5 {
    margin-left: 3rem !important;
  }
  .ms-fhd-auto {
    margin-left: auto !important;
  }
  .p-fhd-0 {
    padding: 0 !important;
  }
  .p-fhd-1 {
    padding: 0.25rem !important;
  }
  .p-fhd-2 {
    padding: 0.5rem !important;
  }
  .p-fhd-3 {
    padding: 1rem !important;
  }
  .p-fhd-4 {
    padding: 1.5rem !important;
  }
  .p-fhd-5 {
    padding: 3rem !important;
  }
  .px-fhd-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-fhd-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-fhd-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-fhd-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-fhd-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-fhd-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-fhd-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-fhd-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-fhd-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-fhd-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-fhd-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-fhd-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-fhd-0 {
    padding-top: 0 !important;
  }
  .pt-fhd-1 {
    padding-top: 0.25rem !important;
  }
  .pt-fhd-2 {
    padding-top: 0.5rem !important;
  }
  .pt-fhd-3 {
    padding-top: 1rem !important;
  }
  .pt-fhd-4 {
    padding-top: 1.5rem !important;
  }
  .pt-fhd-5 {
    padding-top: 3rem !important;
  }
  .pe-fhd-0 {
    padding-right: 0 !important;
  }
  .pe-fhd-1 {
    padding-right: 0.25rem !important;
  }
  .pe-fhd-2 {
    padding-right: 0.5rem !important;
  }
  .pe-fhd-3 {
    padding-right: 1rem !important;
  }
  .pe-fhd-4 {
    padding-right: 1.5rem !important;
  }
  .pe-fhd-5 {
    padding-right: 3rem !important;
  }
  .pb-fhd-0 {
    padding-bottom: 0 !important;
  }
  .pb-fhd-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-fhd-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-fhd-3 {
    padding-bottom: 1rem !important;
  }
  .pb-fhd-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-fhd-5 {
    padding-bottom: 3rem !important;
  }
  .ps-fhd-0 {
    padding-left: 0 !important;
  }
  .ps-fhd-1 {
    padding-left: 0.25rem !important;
  }
  .ps-fhd-2 {
    padding-left: 0.5rem !important;
  }
  .ps-fhd-3 {
    padding-left: 1rem !important;
  }
  .ps-fhd-4 {
    padding-left: 1.5rem !important;
  }
  .ps-fhd-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root {
  --scrollbarsize: 17px;
  --screen-size: calc(100vw - var(--scrollbarsize, 0px));
  --header-height: 70px;
  --header-padding: 5px 15px;
  --header-logo-padding: 5px 0;
  --color-danger: #dc3545;
  --color-rgb-danger: 220,53,69;
  --color-warning: #ffc107;
  --color-rgb-warning: 255,193,7;
  --color-info: #17a2b8;
  --color-rgb-info: 23,162,184;
  --color-success: #28a745;
  --color-rgb-success: 40,167,69;
  --color-primary: #ee921e;
  --color-rgb-primary: 238,146,30;
  --color-primary-darken: #e68811;
  --color-rgb-primary-darken: 230,136,17;
  --color-secondary: #cb3e20;
  --color-rgb-secondary: 203,62,32;
  --color-secondary-darken: #b9391d;
  --color-rgb-secondary-darken: 185,57,29;
  --color-gray-100: #f6f6f6;
  --color-rgb-gray-100: 246,246,246;
  --color-gray-200: #e5e5e5;
  --color-rgb-gray-200: 229,229,229;
  --color-gray-300: #d4d4d4;
  --color-rgb-gray-300: 212,212,212;
  --color-gray-400: #a3a3a3;
  --color-rgb-gray-400: 163,163,163;
  --color-gray-500: #727673;
  --color-rgb-gray-500: 114,118,115;
  --color-gray-600: #525252;
  --color-rgb-gray-600: 82,82,82;
  --color-gray-700: #404040;
  --color-rgb-gray-700: 64,64,64;
  --color-gray-800: #262626;
  --color-rgb-gray-800: 38,38,38;
  --color-gray-900: #171717;
  --color-rgb-gray-900: 23,23,23;
  --color-white: #fff;
  --color-rgb-white: 255,255,255;
  --color-black: #262c28;
  --color-rgb-black: 38,44,40;
  --color-text-color: #a3a3a3;
  --color-rgb-text-color: 163,163,163;
  --color-input-color: #a3a3a3;
  --color-rgb-input-color: 163,163,163;
  --color-bg-color: #f6f6f6;
  --color-rgb-bg-color: 246,246,246;
  --spacing-base: 1.875rem;
  --font-level-16: 1rem;
  --srp-primary: #ee921e;
  --srp-secondary: #cb3e20;
  --srp-success: #198754;
  --srp-info: #0dcaf0;
  --srp-warning: #ffc107;
  --srp-danger: #cb3e20;
  --srp-light: #f6f6f6;
  --srp-dark: #171717;
  --srp-breakpoint-xs: 0;
  --srp-breakpoint-xsm: 358px;
  --srp-breakpoint-sm: 576px;
  --srp-breakpoint-md: 768px;
  --srp-breakpoint-lg: 992px;
  --srp-breakpoint-xl: 1200px;
  --srp-breakpoint-xxl: 1440px;
  --srp-breakpoint-fhd: 1700px;
}
@media (min-width: 992px) {
  :root {
    --header-height: 90px;
    --header-padding: 10px;
    --header-logo-padding: 0;
  }
}
@media (min-width: 1200px) {
  :root {
    --header-height: 110px;
    --header-padding: 15px;
    --header-logo-padding: 0;
  }
}
@media (prefers-reduced-motion: reduce) {
  :root {
    --nomotion: none;
  }
}
@media (min-width: 576px) {
  :root {
    --srp-container-max-width: 540px;
  }
}
@media (min-width: 768px) {
  :root {
    --srp-container-max-width: 720px;
  }
}
@media (min-width: 992px) {
  :root {
    --srp-container-max-width: 960px;
  }
}
@media (min-width: 1200px) {
  :root {
    --srp-container-max-width: 1140px;
  }
}
@media (min-width: 1440px) {
  :root {
    --srp-container-max-width: 1320px;
  }
}
@media (min-width: 1700px) {
  :root {
    --srp-container-max-width: 1440px;
  }
}
@media (min-width: 2000px) {
  :root {
    --custom-vw: 2000px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--header-height);
  overflow-x: hidden;
  background-color: #fff;
  color: #262c28;
}
body.menu-opened, body.modal-open, body.modal-active {
  overflow: hidden;
}
body.modal-open {
  padding-right: var(--scrollbarWidth);
}
body:has(.active-leaves) {
  --top-bar-height: 40px;
}

.page {
  flex: 1;
  overflow: hidden;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.section--centered {
  text-align: center;
}
.section--centered .pre-title::after {
  left: 50%;
  width: 60px;
  transform: translateX(-50%);
}

.fancybox__content {
  padding: 0;
}

.fancybox__slide {
  padding: 45px 10px 10px;
}
@media (min-width: 992px) {
  .fancybox__slide {
    padding: 48px 30px 10px;
  }
}

.fancybox-custom {
  width: 100%;
  max-width: 1100px !important;
  padding: 20px;
}
@media (min-width: 768px) {
  .fancybox-custom {
    padding: 30px;
  }
}
@media (min-width: 992px) {
  .fancybox-custom {
    padding: 40px;
  }
}
.fancybox-custom .h1-like {
  margin-bottom: 20px;
}
.fancybox-custom .h2-like {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
}
.fancybox-custom .h3-like {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 19px;
  font-weight: 600;
}
.fancybox-custom img {
  margin-bottom: 30px;
  border-radius: 10px;
}
.fancybox-custom b,
.fancybox-custom strong {
  font-weight: 600;
}
.fancybox-custom .pre-title {
  text-align: center;
}
.fancybox-custom .pre-title::after {
  left: 50%;
  width: 60px;
  transform: translateX(-50%);
}

table.table-legal {
  width: 100%;
  margin-bottom: 30px;
  border-collapse: collapse;
  border: 2px solid #404040;
  font-size: 14px;
}
table.table-legal th {
  background-color: #404040;
}
table.table-legal th,
table.table-legal th p {
  color: #fff;
}
table.table-legal th,
table.table-legal td {
  padding: 14px;
  border: 2px solid #404040;
}
table.table-legal p {
  margin: 0;
  font-size: 14px;
}

@font-face {
  font-family: "icomoon-srp";
  src: url("../fonts/icomoon/fonts/icomoon-srp.ttf?av5zl7") format("truetype"), url("../fonts/icomoon/fonts/icomoon-srp.woff?av5zl7") format("woff"), url("../fonts/icomoon/fonts/icomoon-srp.svg?av5zl7#icomoon-srp") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon-srp" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconSrp-logo-glanum:before {
  content: "\e90a";
}

.iconSrp-headphones:before {
  content: "\e909";
}

.iconSrp-care:before {
  content: "\e900";
}

.iconSrp-shipping:before {
  content: "\e901";
}

.iconSrp-quality-assurance:before {
  content: "\e902";
}

.iconSrp-lightbulb:before {
  content: "\e903";
}

.iconSrp-design-tools:before {
  content: "\e904";
}

.iconSrp-flag-france .path1:before {
  content: "\e905";
  color: rgb(0, 44, 157);
}

.iconSrp-flag-france .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(246, 246, 246);
}

.iconSrp-flag-france .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(245, 39, 64);
}

.iconSrp-color-sample:before {
  content: "\e908";
}

@font-face {
  font-family: "AR BONNIE";
  src: url("../fonts/ar-bonnie/hinted-ARBONNIE.eot");
  src: url("../fonts/ar-bonnie/hinted-ARBONNIE.eot?#iefix") format("embedded-opentype"), url("../fonts/ar-bonnie/hinted-ARBONNIE.woff2") format("woff2"), url("../fonts/ar-bonnie/hinted-ARBONNIE.woff") format("woff"), url("../fonts/ar-bonnie/hinted-ARBONNIE.ttf") format("truetype"), url("../fonts/ar-bonnie/hinted-ARBONNIE.svg#ARBONNIE") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now";
  src: url("../fonts/now/Now-Bold.eot");
  src: url("../fonts/now/Now-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/now/Now-Bold.woff2") format("woff2"), url("../fonts/now/Now-Bold.woff") format("woff"), url("../fonts/now/Now-Bold.ttf") format("truetype"), url("../fonts/now/Now-Bold.svg#Now-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now Alt";
  src: url("../fonts/now/NowAlt-Thin.eot");
  src: url("../fonts/now/NowAlt-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/now/NowAlt-Thin.woff2") format("woff2"), url("../fonts/now/NowAlt-Thin.woff") format("woff"), url("../fonts/now/NowAlt-Thin.ttf") format("truetype"), url("../fonts/now/NowAlt-Thin.svg#NowAlt-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now";
  src: url("../fonts/now/Now-Black.eot");
  src: url("../fonts/now/Now-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/now/Now-Black.woff2") format("woff2"), url("../fonts/now/Now-Black.woff") format("woff"), url("../fonts/now/Now-Black.ttf") format("truetype"), url("../fonts/now/Now-Black.svg#Now-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now";
  src: url("../fonts/now/Now-Light.eot");
  src: url("../fonts/now/Now-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/now/Now-Light.woff2") format("woff2"), url("../fonts/now/Now-Light.woff") format("woff"), url("../fonts/now/Now-Light.ttf") format("truetype"), url("../fonts/now/Now-Light.svg#Now-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now Alt";
  src: url("../fonts/now/NowAlt-Black.eot");
  src: url("../fonts/now/NowAlt-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/now/NowAlt-Black.woff2") format("woff2"), url("../fonts/now/NowAlt-Black.woff") format("woff"), url("../fonts/now/NowAlt-Black.ttf") format("truetype"), url("../fonts/now/NowAlt-Black.svg#NowAlt-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now Alt";
  src: url("../fonts/now/NowAlt-Regular.eot");
  src: url("../fonts/now/NowAlt-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/now/NowAlt-Regular.woff2") format("woff2"), url("../fonts/now/NowAlt-Regular.woff") format("woff"), url("../fonts/now/NowAlt-Regular.ttf") format("truetype"), url("../fonts/now/NowAlt-Regular.svg#NowAlt-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now Alt";
  src: url("../fonts/now/NowAlt-Medium.eot");
  src: url("../fonts/now/NowAlt-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/now/NowAlt-Medium.woff2") format("woff2"), url("../fonts/now/NowAlt-Medium.woff") format("woff"), url("../fonts/now/NowAlt-Medium.ttf") format("truetype"), url("../fonts/now/NowAlt-Medium.svg#NowAlt-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now";
  src: url("../fonts/now/Now-Medium.eot");
  src: url("../fonts/now/Now-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/now/Now-Medium.woff2") format("woff2"), url("../fonts/now/Now-Medium.woff") format("woff"), url("../fonts/now/Now-Medium.ttf") format("truetype"), url("../fonts/now/Now-Medium.svg#Now-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now Alt";
  src: url("../fonts/now/NowAlt-Bold.eot");
  src: url("../fonts/now/NowAlt-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/now/NowAlt-Bold.woff2") format("woff2"), url("../fonts/now/NowAlt-Bold.woff") format("woff"), url("../fonts/now/NowAlt-Bold.ttf") format("truetype"), url("../fonts/now/NowAlt-Bold.svg#NowAlt-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now";
  src: url("../fonts/now/Now-Regular.eot");
  src: url("../fonts/now/Now-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/now/Now-Regular.woff2") format("woff2"), url("../fonts/now/Now-Regular.woff") format("woff"), url("../fonts/now/Now-Regular.ttf") format("truetype"), url("../fonts/now/Now-Regular.svg#Now-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now Alt";
  src: url("../fonts/now/NowAlt-Light.eot");
  src: url("../fonts/now/NowAlt-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/now/NowAlt-Light.woff2") format("woff2"), url("../fonts/now/NowAlt-Light.woff") format("woff"), url("../fonts/now/NowAlt-Light.ttf") format("truetype"), url("../fonts/now/NowAlt-Light.svg#NowAlt-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Now";
  src: url("../fonts/now/Now-Thin.eot");
  src: url("../fonts/now/Now-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/now/Now-Thin.woff2") format("woff2"), url("../fonts/now/Now-Thin.woff") format("woff"), url("../fonts/now/Now-Thin.ttf") format("truetype"), url("../fonts/now/Now-Thin.svg#Now-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
html {
  font-size: var(--font-level-16);
}

body {
  font-family: "Now Alt";
  font-size: 100%;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Now Alt";
}

a {
  color: var(--srp-primary, #ee921e);
  text-decoration: none;
}
a:hover, a:active, a:focus {
  text-decoration: underline;
}

p {
  color: #727673;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.22;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  p {
    font-size: 20px;
  }
}

.pre-title {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 2px;
  color: #262c28;
  font-family: "AR BONNIE";
  font-size: 30px;
  letter-spacing: 1.2px;
  line-height: 1.2;
}
.pre-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 96px;
  height: 1px;
  background-color: #262c28;
}

.title {
  color: #262c28;
  font-family: "Now Alt";
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .title {
    font-size: 35px;
  }
}
@media (min-width: 992px) {
  .title {
    font-size: 30px;
  }
}
@media (min-width: 1200px) {
  .title {
    font-size: 35px;
  }
}
.title__secondary {
  color: #cb3e20;
}

ul.list {
  margin-top: 30px;
  padding-left: 32px;
  list-style: none;
}
ul.list li {
  position: relative;
  padding-bottom: 8px;
  color: #404040;
  font-size: 18px;
}
ul.list li::before {
  content: "";
  position: absolute;
  top: 6px;
  left: -32px;
  width: 11px;
  height: 11px;
  border-radius: 100px;
  background-color: #ee921e;
}

.text-nowrap {
  white-space: nowrap;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.hide-text {
  padding: 0; /* 1 */
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.topMessage {
  display: none;
}
.topMessage.active-leaves {
  display: block;
  height: auto;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  background-color: #ee921e;
}
.topMessage .leave-message {
  padding: 10px 0;
}
.topMessage .leave-message p {
  margin-bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.22;
  text-align: center;
  text-wrap: balance;
}
@media (min-width: 768px) {
  .topMessage .leave-message p {
    font-size: 16px;
  }
}

.mainHeader {
  position: fixed;
  z-index: 500;
  top: 0;
  right: 0;
  left: 0;
  flex-shrink: 1;
  height: var(--header-height);
  padding: var(--header-padding);
  transition: all 300ms ease-in-out;
  transition-property: padding, height;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
  font-family: "Now";
  text-align: center;
}
@media (max-width: 991.98px) {
  .mainHeader {
    position: fixed;
  }
}
@media (min-width: 992px) {
  body.fixed-header .mainHeader {
    height: 80px;
    padding: 15px var(--header-padding);
  }
}
@media (max-width: 991.98px) {
  .mainHeader .container {
    max-width: initial;
  }
}
.mainHeader .container,
.mainHeader .row {
  align-items: center;
  height: 100%;
}
@media (min-width: 992px) {
  .mainHeader .container,
  .mainHeader .row {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .mainHeader .mainRow {
    justify-content: space-between;
  }
}
@media (max-width: 357.98px) {
  .mainHeader .mainRow {
    flex-wrap: nowrap;
  }
}
.mainHeader .logo {
  height: 100%;
  padding: var(--header-logo-padding);
}
@media (min-width: 992px) {
  .mainHeader .logo {
    margin-right: 25px;
  }
}
@media (min-width: 1200px) {
  .mainHeader .logo {
    margin-right: 30px;
  }
}
@media (min-width: 1440px) {
  .mainHeader .logo {
    margin-right: 100px;
  }
}
.mainHeader .logo img {
  width: auto;
  height: 100%;
}
.mainHeader .baseline {
  font-size: 10px;
  font-weight: 600;
  line-height: 1.1;
  text-align: left;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .mainHeader .baseline {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .mainHeader .baseline {
    font-size: 11px;
  }
}
@media (min-width: 1200px) {
  .mainHeader .baseline {
    font-size: 12px;
  }
}
@media (min-width: 1440px) {
  .mainHeader .baseline {
    font-size: 17px;
  }
}
.mainHeader .nav {
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 991.98px) {
  .mainHeader .nav {
    position: fixed;
    top: var(--header-height);
    right: 0;
    bottom: 0;
    left: 0;
    flex: 1;
    flex-direction: column;
    transform: translateX(105%);
    transition: transform 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
}
@media (max-width: 991.98px) {
  .mainHeader .nav .nav-and-cta {
    flex-direction: column;
    padding: 20px 20px 40px;
  }
}
@media (min-width: 992px) {
  .mainHeader .nav .nav-and-cta {
    justify-content: flex-end;
  }
}
body.menu-opened .mainHeader .nav {
  transform: translateX(0);
}
.mainHeader .nav ul.header-menu {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (max-width: 991.98px) {
  .mainHeader .nav ul.header-menu {
    flex-direction: column;
  }
}
.mainHeader .nav ul.header-menu li {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .mainHeader .nav ul.header-menu li {
    margin-bottom: 0;
  }
  .mainHeader .nav ul.header-menu li:not(:last-child) {
    margin-right: 35px;
  }
}
@media (min-width: 1200px) {
  .mainHeader .nav ul.header-menu li:not(:last-child) {
    margin-right: 55px;
  }
}
.mainHeader .nav ul.header-menu a {
  transition: color 200ms ease-in-out;
  color: #404040;
  font-size: 15px;
  font-weight: 300;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mainHeader .nav ul.header-menu a {
    font-size: 14px;
  }
}
.mainHeader .nav ul.header-menu a:hover, .mainHeader .nav ul.header-menu a:active, .mainHeader .nav ul.header-menu a:focus {
  color: #262c28;
  text-decoration: none;
}
.mainHeader .nav li.active a {
  color: #262c28;
  font-weight: 700;
}
.mainHeader .button {
  display: block;
  padding: 12px 20px;
  font-size: 15px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mainHeader .button {
    padding: 6px 12px;
    font-size: 14px;
  }
}
.mainHeader .burger-menu {
  padding: 0;
}
@media (min-width: 992px) {
  .mainHeader .burger-menu {
    display: none;
  }
}
.mainHeader .burger-menu .burger-menu__button {
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.mainHeader .burger-menu .burger-menu__button svg {
  height: 44px;
}
.mainHeader .burger-menu .line {
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  stroke-width: 6;
  stroke: #262c28;
  fill: none;
}
.mainHeader .burger-menu .line1 {
  stroke-width: 6;
  stroke-dasharray: 60 207;
}
.mainHeader .burger-menu .line2 {
  stroke-width: 6;
  stroke-dasharray: 60 60;
}
.mainHeader .burger-menu .line3 {
  stroke-width: 6;
  stroke-dasharray: 60 207;
}
body.menu-opened .mainHeader .burger-menu .line1 {
  stroke-width: 6;
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}
body.menu-opened .mainHeader .burger-menu .line2 {
  stroke-width: 6;
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
}
body.menu-opened .mainHeader .burger-menu .line3 {
  stroke-width: 6;
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}

footer#site-footer {
  background-color: #cb3e20;
  padding: 10px;
  color: #fff;
  font-size: 14px;
}
@media (max-width: 991.98px) {
  footer#site-footer {
    text-align: center;
  }
}
footer#site-footer .mainRow {
  justify-content: space-between;
  row-gap: 20px;
}
footer#site-footer a {
  color: #fff;
}
footer#site-footer a:hover, footer#site-footer a:active, footer#site-footer a:focus {
  color: #fff;
}
footer#site-footer .footer__links {
  row-gap: 5px;
  column-gap: 20px;
  flex-direction: column;
}
@media (min-width: 768px) {
  footer#site-footer .footer__links {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  footer#site-footer .footer__links {
    column-gap: 40px;
  }
}
footer#site-footer .logo-glanum {
  display: inline-block;
  font-size: 20px;
  line-height: 0.2;
}
footer#site-footer .logo-glanum i {
  display: inline-block;
  transition: transform 1s ease;
}
footer#site-footer .logo-glanum:hover, footer#site-footer .logo-glanum:active, footer#site-footer .logo-glanum:focus {
  text-decoration: none;
}
footer#site-footer .logo-glanum:hover i, footer#site-footer .logo-glanum:active i, footer#site-footer .logo-glanum:focus i {
  transform: rotate(360deg);
}

#hero {
  background-color: #f7d3bc;
  background-image: url("../img/hero-bg.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 80%;
}
@media (min-width: 992px) {
  #hero {
    background-size: 28%;
  }
}
@media (min-width: 1200px) {
  #hero .title {
    font-size: 40px;
  }
}
#hero p {
  color: #262c28;
}
#hero .small {
  font-size: 14px;
  font-style: italic;
}

#partenaires {
  padding: 45px 0;
}
#partenaires .pre-title {
  margin-bottom: 45px;
}
#partenaires .container,
#partenaires .mainRow {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 2rem;
}
@media (min-width: 992px) {
  #partenaires .container,
  #partenaires .mainRow {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 6rem;
  }
}
#partenaires img {
  width: 100%;
}

.nos-avantages .container,
.nos-avantages .mainRow {
  --bs-gutter-x: 4rem;
  --bs-gutter-y: 2rem;
}
@media (min-width: 992px) {
  .nos-avantages .container,
  .nos-avantages .mainRow {
    --bs-gutter-x: 6rem;
    --bs-gutter-y: 6rem;
  }
}
.nos-avantages .title {
  margin-bottom: 60px;
}
@media (min-width: 1200px) {
  .nos-avantages .title {
    font-size: 40px;
  }
}
.nos-avantages .item__icon {
  display: block;
  font-size: 46px;
  margin-bottom: 20px;
  background: #ee921e;
  display: inline-block;
  border-radius: 200px;
  padding: 13px;
  color: #fff;
}
.nos-avantages .item__title {
  font-family: "Now";
  font-size: 20px;
  font-weight: 300;
  color: #262c28;
  line-height: 1.2;
  margin-bottom: 6px;
  font-weight: 500;
}
.nos-avantages .item__text {
  font-family: "Now";
  font-size: 18px;
  font-weight: 300;
  color: #727673;
  line-height: 1.2;
}

.boxed {
  padding: 40px 0;
}
.boxed .boxedWrapper {
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 50px 25px;
}
.boxed .mainRow {
  row-gap: 30px;
}

#prefooter {
  padding: 40px 0;
  margin-top: 50px;
  background-color: #f6f6f6;
  text-align: center;
}
#prefooter .item__image {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 5px;
}
#prefooter .item__icon {
  display: block;
  font-size: 46px;
  margin-bottom: 20px;
}
#prefooter .item__title {
  font-family: "Now";
  font-size: 20px;
  font-weight: 300;
  color: #262c28;
  line-height: 1.2;
  margin-bottom: 6px;
}

.button {
  display: inline-block;
  position: relative;
  z-index: 2;
  padding: 10px 30px;
  overflow: hidden;
  border: none;
  outline: none;
  background-color: #ee921e;
  box-shadow: none;
  color: #fff;
  font-family: "Now";
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
}
.button:hover, .button:active, .button:focus {
  outline: none;
  background-color: #e68811;
  box-shadow: none;
  color: #fff;
  text-decoration: none;
}
.button--small {
  padding: 4px 22px 3px 22px;
  font-size: 13px !important;
}
.button--secondary {
  background-color: #cb3e20;
}
.button--secondary:hover, .button--secondary:active, .button--secondary:focus {
  background-color: #b9391d;
}

.image-text {
  padding: 45px 0;
}
.image-text--inverted .mainRow {
  flex-direction: row-reverse;
}
.image-text--boxed .mainRow {
  background: #ee921e;
  padding: 30px;
  border-radius: 10px;
}
.image-text--boxed > .container {
  padding-left: calc(var(--bs-gutter-x) * 0.5 + 0.75rem);
  padding-right: calc(var(--bs-gutter-x) * 0.5 + 0.75rem);
}
@media (min-width: 992px) {
  .image-text--boxed > .container, .image-text--boxed > .container .row {
    --bs-gutter-x: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .image-text--boxed > .container, .image-text--boxed > .container .row {
    --bs-gutter-x: 5rem;
  }
}
.image-text--lightText .pre-title, .image-text--lightText .title, .image-text--lightText p {
  color: #fff;
}
.image-text--lightText .pre-title::after {
  background-color: #fff;
}
.image-text .mainRow {
  align-items: center;
  row-gap: 50px;
}
@media (min-width: 992px) {
  .image-text__textWrapper .title {
    padding-right: 13%;
  }
}
.image-text__textWrapper p {
  margin-top: 16px;
}
.image-text__textWrapper strong {
  font-weight: bold;
}
.image-text__textWrapper .button {
  margin-top: 20px;
}
.image-text__imageWrapper img {
  width: 100%;
}
.image-text {
  padding: 45px 0;

  &--inverted {
    .mainRow {
      flex-direction: row-reverse;
    }
  }

  &--boxed {
    .mainRow {
      background: $primary;
      padding: 30px;
      border-radius: 10px;
    }

    & > {
      .container {
        padding-left: calc((var(--bs-gutter-x) * 0.5) + (1.5rem * 0.5));
        padding-right: calc((var(--bs-gutter-x) * 0.5) + (1.5rem * 0.5));

        &, .row {
          @include media-breakpoint-up(lg) {
            --bs-gutter-x: 2.5rem;
          }
          @include media-breakpoint-up(xl) {
            --bs-gutter-x: 5rem;
          }
        }
      }
    }

  }

  &--lightText {
    .pre-title, .title, p {
      color: $white;
    }
    .pre-title {
      &::after {
        background-color: $white;
      }
    }
  }

  .mainRow {
    align-items: center;
    row-gap: 50px;
  }

  &__textWrapper {
    .title {
      @include media-breakpoint-up(lg) {
        padding-right: 13%;
      }
    }

    p {
      margin-top: 16px;
    }
    strong {
      font-weight: $fw-bold;
    }

    .button {
      margin-top: 20px;
    }
  }

  &__imageWrapper {
    img {
      width: 100%;
    }
  }
}

.nos-avantages {
  .container,
  .mainRow {
    --bs-gutter-x: 4rem;
    --bs-gutter-y: 2rem;

    @include media-breakpoint-up(lg) {
      --bs-gutter-x: 6rem;
      --bs-gutter-y: 6rem;
    }
  }
  
  .title {
    margin-bottom: 60px;
    @include media-breakpoint-up(xl) {
      font-size: 40px;
    }
  } 

  .item {
    &__icon {
      display: block;
      font-size: 46px;
      margin-bottom: 20px;
      background: $primary;
      display: inline-block;
      border-radius: 200px;
      padding: 13px;
      color: $white;
    }
    &__title {
      font-family: $font-now;
      font-size: 20px;
      font-weight: $fw-light;
      color: $black;
      line-height: 1.2;
      margin-bottom: 6px;
      font-weight: $fw-medium;
    }
    &__text {
      font-family: $font-now;
      font-size: 18px;
      font-weight: $fw-light;
      color: $gray-500;
      line-height: 1.2;
    }
  }
}

// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer#site-footer {
  background-color: $secondary;
  padding: 10px;
  color: $white;
  font-size: 14px;

  @include media-breakpoint-down(lg) {
    text-align: center;
  }

  .mainRow {
    justify-content: space-between;
    row-gap: 20px;
  }

  a {
    color: $white;
  
    @include on-event {
      color: $white;
    }
  }

  .footer__links {
    row-gap: 5px;
    column-gap: 20px;

    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    @include media-breakpoint-up(lg) {
      column-gap: 40px;

    }
  }

  .logo-glanum {
    display: inline-block;
    font-size: 20px;
    line-height: 0.2;

    i {
      display: inline-block;
      transition: transform 1s ease;
    }

    @include on-event {
      text-decoration: none;

      i {
        transform: rotate(360deg);
      }
    }
  }
}

#hero {
  background-color: $peach;
  background-image: url('../img/hero-bg.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 80%;

  @include media-breakpoint-up(lg) {
    background-size: 28%;
  }

  .title {
    @include media-breakpoint-up(xl) {
      font-size: 40px;
    }
  }
  p {
    color: $black;
  }

  .small {
    font-size: 14px;
    font-style: italic;
  }
}

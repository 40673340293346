@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?av5zl7') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?av5zl7') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?av5zl7##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconSrp-logo-glanum {
  &:before {
    content: $iconSrp-logo-glanum; 
  }
}
.iconSrp-headphones {
  &:before {
    content: $iconSrp-headphones; 
  }
}
.iconSrp-care {
  &:before {
    content: $iconSrp-care; 
  }
}
.iconSrp-shipping {
  &:before {
    content: $iconSrp-shipping; 
  }
}
.iconSrp-quality-assurance {
  &:before {
    content: $iconSrp-quality-assurance; 
  }
}
.iconSrp-lightbulb {
  &:before {
    content: $iconSrp-lightbulb; 
  }
}
.iconSrp-design-tools {
  &:before {
    content: $iconSrp-design-tools; 
  }
}
.iconSrp-flag-france .path1 {
  &:before {
    content: $iconSrp-flag-france-path1;  
    color: rgb(0, 44, 157);
  }
}
.iconSrp-flag-france .path2 {
  &:before {
    content: $iconSrp-flag-france-path2;  
    margin-left: -1em;  
    color: rgb(246, 246, 246);
  }
}
.iconSrp-flag-france .path3 {
  &:before {
    content: $iconSrp-flag-france-path3;  
    margin-left: -1em;  
    color: rgb(245, 39, 64);
  }
}
.iconSrp-color-sample {
  &:before {
    content: $iconSrp-color-sample; 
  }
}


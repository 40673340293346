.boxed {
  padding: 40px 0;

  // & > {
  //   .container {
  //     &, .row {
  //       --bs-gutter-x: 3rem;
  //     }
  //   }
  // }

  .boxedWrapper {
    background-color: $gray-100;
    border-radius: 5px;
    padding: 50px 25px;
  }

  .mainRow {
    row-gap: 30px;
  }
}

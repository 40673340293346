@font-face {
    font-family: 'AR BONNIE';
    src: url('#{$fonts-path}/ar-bonnie/hinted-ARBONNIE.eot');
    src: url('#{$fonts-path}/ar-bonnie/hinted-ARBONNIE.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-path}/ar-bonnie/hinted-ARBONNIE.woff2') format('woff2'),
        url('#{$fonts-path}/ar-bonnie/hinted-ARBONNIE.woff') format('woff'),
        url('#{$fonts-path}/ar-bonnie/hinted-ARBONNIE.ttf') format('truetype'),
        url('#{$fonts-path}/ar-bonnie/hinted-ARBONNIE.svg#ARBONNIE') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

